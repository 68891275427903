import React from 'react';
import { cx } from 'linaria';
import {
  footerComponentsStandard,
  footerComponentsSpecial
} from '../../ContentEditor/ContentComponents';
import { useQuery } from 'react-apollo';
import CategoryContentQuery from '../../ContentEditor/DynamicCategoryRenderer/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';

const Footer = () => {
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: process?.env?.REACT_APP_FOOTER_CATEGORY_ID
    }
  });
  if (!data || error) {
    return null;
  }

  const filterCEItemsWithType = (items, type) => {
    return items?.filter(item => item?.type === type);
  };

  const footerInfo = filterCEItemsWithType(
    data?.category?.data?.items,
    'Footer-1'
  )?.[0];
  const columnLinks = filterCEItemsWithType(
    data?.category?.data?.items,
    'Footer-2-0-Column'
  );
  const paymenMethods = filterCEItemsWithType(
    data?.category?.data?.items,
    'PaymentMethods'
  );

  const newFooterComp = {
    ...footerInfo,
    children: [...footerInfo?.children, ...columnLinks, ...paymenMethods]
  };

  const newFooterCompStandard = {
    ...footerInfo,
    children: [...footerInfo?.children, ...columnLinks]
  };

  const footerVariant = process.env.REACT_APP_FOOTER_VARIANT_ID;

  let selectedComponents;
  if (footerVariant === 'standard') {
    selectedComponents = footerComponentsStandard;
  } else if (footerVariant === 'special') {
    selectedComponents = footerComponentsSpecial;
  } else {
    selectedComponents = footerComponentsSpecial;
  }

  return (
    <div className={cx('footer')}>
      {selectedComponents && (
        <ContentRenderer
          items={
            footerVariant === 'standard'
              ? [newFooterCompStandard, ...paymenMethods]
              : [newFooterComp]
          }
          components={selectedComponents}
        />
      )}
    </div>
  );
};

export default Footer;
